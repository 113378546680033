



























































































import {
  computed,
  defineComponent, onMounted,
  ref,
  Ref, SetupContext
} from "@vue/composition-api";
import axios from "axios";
import i18n from "./plugins/vue-i18n";
import LoginCard from "@/pages/login/components/LoginCard.vue";
import BaseCard from "@/pages/login/components/BaseCard.vue";
import RecoveryCard from "@/pages/login/components/RecoveryCard.vue";

// https://stackoverflow.com/questions/12709074/how-do-you-explicitly-set-a-new-property-on-window-in-typescript
declare global {
  interface Window {
    loginCardStyles: {
      horizontalAlign: string;
      verticalAlign: string;
      cardDark: string; // boolean
			cardButtonBgColor: string;
			cardButtonFgColor: string;
      cardBackgroundColor: string;
      cardStyle: string;
      cardWidth: string;
      backgroundImage: string;
			cardLogoImage: string;
			cardLogoImageInside: string;
			loginStyle: string;
			hideDefaultBgImage: string; // boolean
			hideDefaultCardLogo: string; // boolean
			loginButtonWidth: string;
    },
    showRecoveryButton: string; // boolean
  }
}

export default defineComponent({
  name: "LoginApp",
  components: {
    BaseCard,
    LoginCard,
    RecoveryCard
  },
  setup(_, context: SetupContext) {
    // Handlebars variables that are loaded in the login.html inside a tag script
    const loginCardStyles = window.loginCardStyles;
    const verticalAlign = loginCardStyles.verticalAlign && loginCardStyles.verticalAlign.length > 0 ? loginCardStyles.verticalAlign : 'center';
    const horizontalAlign = loginCardStyles.horizontalAlign && loginCardStyles.horizontalAlign.length > 0 ? loginCardStyles.horizontalAlign : 'center';

    // TODO: hide background image for display gradient background color in css
    // const hideDefaultBgImage = loginCardStyles.hideDefaultBgImage === "true";
    const hideDefaultBgImage = false;

    /**
     *  Get a different image every time that page reloads
     */
    const image = require('./assets/background.jpg');

    const snackbar: Ref<boolean> = ref(false);

    /**
     * If config file has recovery url then the loginCard emits an event to show the RecoveryCard
     */
    const showRecoveryCard: Ref<boolean> = ref(false);

    /**
     *
     */
    const isSmallDevice = computed(() => {
      const breakpoint = context.root.$vuetify.breakpoint;
      return breakpoint.smAndDown || breakpoint.mobile
    });

    const cardWidth = computed(() => {
      let width = "400px";

      if (loginCardStyles.cardWidth.length > 0) {
        width = loginCardStyles.cardWidth
      }

      if (isSmallDevice.value) {
        width = "100%";
      }

      return width;
    })

    onMounted(() => {
      //@ts-ignore
      const language = window.navigator.userLanguage || window.navigator.language;
      i18n.locale = language.slice(0, 2);
    })

    return {
      // Handlebars styles and variables
      loginCardStyles,
      horizontalAlign,
      verticalAlign,
      hideDefaultBgImage,
      cardWidth,

      image,
      snackbar,
      isSmallDevice,
      showRecoveryCard
    }
  }
});
