// Ready translated locale messages
export const messages = {
	en: {
		ID_PASSWORD: "Password",
		ID_USERNAME: "Username or email",
		ID_LOGIN: "Login",
		ID_UNAUTHORIZED: "Incorrect username or password."
	},
	es: {
		ID_PASSWORD: "Contraseña",
		ID_USERNAME: "Nombre de usuario o correo electrónico",
		ID_LOGIN: "Iniciar session",
		ID_UNAUTHORIZED: "Nombre de usuario o contraseña incorrectos."
	},
	ca: {
		ID_PASSWORD: "Contrasenya",
		ID_USERNAME: "Nom d'usuari o correu electrònic",
		ID_LOGIN: "Iniciar sessió",
		ID_UNAUTHORIZED: "Nom d'usuari o contrasenya incorrectes."
	},
}
