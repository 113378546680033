import Vue          from 'vue'
import vuetify      from './plugins/vuetify';
import LoginApp     from './LoginApp.vue'

import "./plugins/composition-api";
import i18n from "./plugins/vue-i18n";

// Set this to false to prevent the production tip on Vue startup.
Vue.config.productionTip = false;

new Vue({
    vuetify,
    i18n,
    render: h => h(LoginApp)
}).$mount('#appLogin');
