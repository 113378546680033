












































import {defineComponent, ref, Ref} from "@vue/composition-api";
import {axiosInstance} from "@/plugins/axios-instance";

export default defineComponent({
  name: "RecoveryCard",
  setup() {
    // Handlebars variables that are loaded in the login.html inside a tag script
    const loginCardStyles = window.loginCardStyles;

    const loginButtonWidth = loginCardStyles.loginButtonWidth.length > 0 ? loginCardStyles.loginButtonWidth : '';
    const cardButtonBgColor = loginCardStyles.cardButtonBgColor.length > 0 ? loginCardStyles.cardButtonBgColor : 'primary';

    //TODO; how to inject booleans using handlebars
    const cardDark = loginCardStyles.cardDark == "true";

    /**
     *
     */
    const loading:Ref<boolean> = ref(false);

    /**
     * Holds the username input field v-model value.
     */
    const username: Ref<string> = ref("");

    /**
     *
     */
    async function submit(): Promise<void>
    {
      try {
        await axiosInstance.post("/recover", {
          username: username.value
        })
      } catch (e) {
        console.error(e.message)
      }
    }

    return {
      loginButtonWidth,
      cardButtonBgColor,
      cardDark,
      username,
      loading,
      submit
    }
  }
})
