var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{staticStyle:{"height":"100%"},style:({
          'padding': _vm.isSmallDevice ? '0px' : '',
        }),attrs:{"fluid":_vm.isSmallDevice}},[(_vm.loginCardStyles && _vm.loginCardStyles.backgroundImage)?_c('v-img',{staticClass:"login-background-image",attrs:{"src":_vm.loginCardStyles.backgroundImage}}):(_vm.image != null && !_vm.hideDefaultBgImage)?_c('v-img',{staticClass:"login-background-image",attrs:{"src":_vm.image}}):_vm._e(),_c('v-row',{staticStyle:{"height":"100%"},style:(_vm.isSmallDevice ? 'padding. 0px; margin: 0px' : ''),attrs:{"align":_vm.verticalAlign}},[_c('v-col',{style:({
              'height': _vm.isSmallDevice ? '100%': '',
              'padding': _vm.isSmallDevice ? '0px' : ''
            }),attrs:{"align":_vm.horizontalAlign}},[_c('div',{staticClass:"login-card-container",style:({'width': _vm.cardWidth, 'justify-content' : _vm.isSmallDevice ? 'center' : ''})},[(_vm.loginCardStyles && _vm.loginCardStyles.cardLogoImage)?_c('v-img',{staticClass:"mb-2",staticStyle:{"width":"fit-content"},attrs:{"src":_vm.loginCardStyles.cardLogoImage,"contain":""}}):_vm._e(),(!_vm.showRecoveryCard)?_c('base-card',[_c('login-card',{on:{"showSnackbar":function($event){_vm.snackbar = true},"showRecovery":function($event){_vm.showRecoveryCard = true}}})],1):_vm._e(),(_vm.showRecoveryCard)?_c('base-card',[_c('recovery-card',{on:{"showSnackbar":function($event){_vm.snackbar = true},"hideRecovery":function($event){_vm.showRecoveryCard = false}}})],1):_vm._e()],1)])],1)],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
            var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" 500 - Internal server error ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }