

























import {computed, defineComponent, SetupContext} from "@vue/composition-api";

export default defineComponent({
  name: "LoginCard",
  setup(_, context: SetupContext) {
    const logo =  require('../assets/logo.png');

    // Handlebars variables that are loaded in the login.html inside a tag script
    const loginCardStyles = window.loginCardStyles;

    const cardWidth = computed(() => {
      let width = "400px";

      if (loginCardStyles.cardWidth.length > 0) {
        width = loginCardStyles.cardWidth
      }

      if (isSmallDevice.value) {
        width = "100%";
      }

      return width;
    })

    //TODO; how to inject booleans using handlebars
    const cardDark = loginCardStyles.cardDark == "true";

    /**
     *
     */
    const isSmallDevice = computed(() => {
      const breakpoint = context.root.$vuetify.breakpoint;
      return breakpoint.smAndDown || breakpoint.mobile
    });


    return {
      loginCardStyles,
      cardDark,
      cardWidth,
      isSmallDevice,
      logo,
    }
  }
})
