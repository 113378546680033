














































































import { defineComponent, ref, Ref, SetupContext } from "@vue/composition-api";
import axios from "axios";

export default defineComponent({
  name: "LoginCard",
  setup(_, context: SetupContext) {
    // Handlebars variables that are loaded in the login.html inside a tag script
    const loginCardStyles = window.loginCardStyles;

    const loginButtonWidth = loginCardStyles.loginButtonWidth.length > 0 ? loginCardStyles.loginButtonWidth : '';
    const cardButtonBgColor = loginCardStyles.cardButtonBgColor.length > 0 ? loginCardStyles.cardButtonBgColor : 'primary';

    //TODO; how to inject booleans using handlebars
    const cardDark = loginCardStyles.cardDark == "true";
    const showRecoveryButton = window.showRecoveryButton == "true";

    /**
     *
     */
    const loading:Ref<boolean> = ref(false);

    /**
     * Show or hide password text
     */
    const passwordVisible:Ref<boolean> = ref(false);

    /**
     * Holds the username input field v-model value.
     */
    const username: Ref<string> = ref("");

    /**
     * Holds the password input field v-model value.
     */
    const password: Ref<string> = ref("");

    const textFieldErrorMessage: Ref<boolean> = ref(false);

    /**
     * Performs login API request call.
     */
    async function submit(): Promise<void> {
      try {
        loading.value = true;
        await axios.post("/login", {
          username: username.value,
          password: password.value
        });
        // window.location.replace("/app");
				window.location.reload();
      } catch(error) {
        if (error.response.status === 401) {
          textFieldErrorMessage.value = true;
        } else if (error.response.status === 500) {
          context.emit("showSnackbar")
        }
      } finally {
        loading.value = false;
      }
    }

    return {
      loginCardStyles,
      loginButtonWidth,
      cardButtonBgColor,
      cardDark,
      showRecoveryButton,

      username,
      password,
      textFieldErrorMessage,
      loading,
      passwordVisible,
      submit
    }
  }
})
